// extracted by mini-css-extract-plugin
export var ReadyOpportunity = "Opportunity-module--ReadyOpportunity--2ad6b";
export var bannerBtn = "Opportunity-module--bannerBtn--d217a";
export var btn_white2_border_Golang = "Opportunity-module--btn_white2_border_Golang--cf325";
export var card = "Opportunity-module--card--e1f25";
export var cardWrapper = "Opportunity-module--cardWrapper--efb50";
export var cmsHeading = "Opportunity-module--cmsHeading--bc81b";
export var cmsOpportunityBanner = "Opportunity-module--cmsOpportunityBanner--287cf";
export var cmsWebDescription = "Opportunity-module--cmsWebDescription--2db2b";
export var con = "Opportunity-module--con--eb881";
export var description = "Opportunity-module--description--e2cf4";
export var description2 = "Opportunity-module--description2--00fa3";
export var heading = "Opportunity-module--heading--ee196";
export var hireCtoOpportunityBanner = "Opportunity-module--hireCtoOpportunityBanner--f6ad2";
export var noCodeHeading = "Opportunity-module--noCodeHeading--57e39";
export var nocodeopportunity = "Opportunity-module--nocodeopportunity--9066a";
export var productDescription = "Opportunity-module--productDescription--74899";
export var productHeading = "Opportunity-module--productHeading--ff7a4";
export var productOpportunityBanner = "Opportunity-module--productOpportunityBanner--23357";
export var readyWebOpportunity = "Opportunity-module--readyWebOpportunity--c1c8c";
export var rescueHeading = "Opportunity-module--rescueHeading--289c8";
export var subserviceOpportunityWeb = "Opportunity-module--subserviceOpportunityWeb--461fd";
export var vsJsBannerBg = "Opportunity-module--vsJsBannerBg--74d07";
export var vsJsBannerDec = "Opportunity-module--vsJsBannerDec--061f5";
export var webAppOpportunity = "Opportunity-module--webAppOpportunity--b0248";