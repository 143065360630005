// extracted by mini-css-extract-plugin
export var Frame = "Toolkit-module--Frame--72124";
export var Rec = "Toolkit-module--Rec--b8e42";
export var advance = "Toolkit-module--advance--19f6e";
export var backendToolkitBg = "Toolkit-module--backendToolkitBg--30da7";
export var cir = "Toolkit-module--cir--7228b";
export var dots = "Toolkit-module--dots--59601";
export var for1 = "Toolkit-module--for1--85f3d";
export var heading = "Toolkit-module--heading--2bb8d";
export var iconssCard = "Toolkit-module--iconssCard--db807";
export var techBg = "Toolkit-module--techBg--78996";
export var techDot = "Toolkit-module--techDot--80b74";
export var techIcon = "Toolkit-module--techIcon--b5a50";
export var techImg = "Toolkit-module--techImg--e67e4";
export var technologyIcon = "Toolkit-module--technologyIcon--70397";