import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-pages-compoents/WhyShould"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import Maximize from "../components/hire-pages-compoents/Maximize"
import HowHireAngular from "../components/hire-python-developers/HowHireAngular"
import HireDevelopers from "../components//hire-pages-compoents/HireDevelopers"
import Toolkit from "../components/react-native/Toolkit"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import QuickReads from "../components/hire-laravel-developers/QuickReads"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Remote from "../components/hire-pages-compoents/Remote"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import Engineering from "../components/hire-pages-compoents/Engineering"
import Finest from "../components/hire-pages-compoents/Opportunity"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const HireBackend = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const why = data?.strapiPage?.sections[2]
  const maximizeAngular = data?.strapiPage?.sections[3]
  const developers = data?.strapiPage.sections[4]
  const how = data?.strapiPage?.sections[5]
  const rubust = data?.strapiPage?.sections[6]
  const remote = data?.strapiPage?.sections[7]
  const best = data?.strapiPage?.sections[8]
  const TechStacksAngular = data?.strapiPage?.sections[9]
  const cta = data?.strapiPage?.sections[10]
  const industriesAngular = data?.strapiPage?.sections[11]
  const solutions = data?.strapiPage?.sections[12]
  const awesome = data?.strapiPage?.sections[13]
  const quick = data?.strapiPage?.sections[14]
  const faqs = data?.strapiPage?.sections[15]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Arthur M. Davis",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/arthur_dev_f3a88013a1.png",
      experience: "8+ years of experience",
      desc: "Leading high-impact projects, Arthur is adept in implementing microservices architecture and also executing CI/CD.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_df89fd802b.svg",
      reviews: "<b>5.0</b> Reviews",
      skills: [
        "Python",
        "PHP",
        "Web Sockets",
        "JavaScript",
        "PostgreSQL",
        "Jenkins",
        "Restful API",
        "SOAP",
        "Nuxt.js",
        "MongoDB",
        "RoR",
        "Express.js",
        "NodeJs",
        "Django",
        "SQLite",
      ],
    },
    {
      name: "Ahmed Wahaj",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ahmed_dev_7bf10409dc.png",
      experience: "5+ years of experience",
      desc: "Solid foundation in the management of databases, Ahmed is also skilled in API integration and his problem-solving approach makes him precious.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "PHP",
        " JavaScript",
        "React",
        "Restful API",
        "SOAP",
        "Nuxt.js",
        "MongoDB",
        "NodeJs",
        "Angular",
        "Vue, SQLite",
        "Python",
        "Express",
      ],
    },
    {
      name: "Arlene Owens",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006598_2_664ffe9239.png",
      experience: "2+ Years Of Experience",
      desc: "Arlene is a quick learner who contributes to collaborative projects with full dedication and enthusiasm & has a growing skill set in back-end development.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: ["JavaScript", "MySQL", "PHP", "MongoDB", "NodeJs", "Vue"],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} hireBackend={true} />
      <Statics />
      <WhyHireAngular strapiData={whyhire} />
      <Engineering strapiData={why} />
      <Maximize strapiData={maximizeAngular} hireBackend={true} />
      <HireDevelopers strapiData={developers} devSDetails={devSDetails} />
      <HowHireAngular strapiData={how} />
      <Empower strapiData={rubust} Vueidentify={true} />
      <Remote strapiData={remote} />
      <Combination strapiData={best} />
      <Toolkit strapiData={TechStacksAngular} backendToolkit={true} />
      <Finest strapiData={cta} backendBudget={true} vsJsBanner={true} />
      <AngularIndustries strapiData={industriesAngular} backedExpert={true} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={awesome} />
      <QuickReads strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query HireBackend {
    strapiPage(slug: { eq: "hire-backend-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default HireBackend
